<template>
  <div ref="container" class="relative col-span-12">
    <div
      class="relative flex items-center justify-between bg-white border-2 cursor-pointer dropdown my-sm md:hidden"
      :class="isContentVisible ? 'open' : ''"
      @click="onToggleContent(!isContentVisible)"
    >
      <span class="truncate mx-xs">
        {{ title.length ? title : t('multiSelectBox.selectionDefault') }}
      </span>
      <div
        v-show="isContentVisible"
        class="relative border-l-2 pl-sm mr-xs text-bold"
      >
        <FaIcon icon-class="fas fa-chevron-up" classes="" />
      </div>
      <div
        v-show="!isContentVisible"
        class="relative border-l-2 pl-sm mr-xs text-bold"
      >
        <FaIcon icon-class="fas fa-chevron-down" />
      </div>
    </div>
    <div
      v-if="!isMobileScreen || isContentVisible"
      class="absolute z-20 flex flex-col-reverse w-full grid-cols-12 col-span-12 shadow-lg select-none md:grid bg-background-base pt-sm pl-sm pr-sm md:shadow-none md:relative rounded-alt-md"
    >
      <div
        class="flex flex-row flex-wrap w-full col-span-12 md:col-span-10 md:flex-row max-h-[370px] md:max-h-none overflow-auto mb-sm"
        :class="{
          'md:col-span-12': !hasResetOption,
        }"
      >
        <div
          v-for="option in options"
          :key="option.value"
          class="w-full text-center cursor-pointer entry px-sm py-2xs bg-activesection-alpha md:mr-sm mb-sm rounded-xs md:w-auto"
          :class="{
            'bg-primary-base ease-in duration-300 text-white':
              selectedItems[option.value] === true,
            'inactive bg-btn-primary-base-opc50 ease-in duration-300 text-border-dark cursor-not-allowed':
              !option.isAvailable,
          }"
          @click="onToggleSelection(option)"
        >
          <label class="text-sm">
            {{ option.label }}
          </label>
        </div>
      </div>
      <div
        v-if="hasResetOption"
        class="flex items-center justify-between col-span-12 text-sm cursor-pointer md:col-span-2 md:border-l-2 text-primary-base mb-sm"
      >
        <div class="md:ml-md mr-xs" @click="resetSelection">
          <FaIcon icon-class="fas fa-arrow-rotate-left" />
          {{ t('multiSelectBox.selectionUndo') }}
        </div>
        <div class="md:hidden" @click="onToggleContent(false)">
          <FaIcon
            icon-class="fas fa-xmark"
            classes="md:ml-md mr-xs text-bold"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';

type MultiSelectItem = {
  value: string;
  label: string;
  isAvailable: boolean;
};

const { t } = useTrans();

const isMobileScreen = inject(mq_breakpointIsMobile);

const props = defineProps({
  modelValue: {
    type: Array<string>,
    required: true,
    default: [],
  },
  options: {
    type: Array<MultiSelectItem>,
    required: true,
  },
  hasResetOption: {
    type: Boolean,
    required: false,
    default: () => true,
  },
});

const selectedItems = reactive<Record<string, boolean>>(
  props.modelValue.reduce(
    (acc, cur) => {
      acc[cur] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  ),
);
const isContentVisible = ref(false);

const container = ref();
onClickOutside(container, () => (isContentVisible.value = false));

const emit = defineEmits<{
  (e: 'update:modelValue', values: string[]): void;
}>();

watch(selectedItems, (v) =>
  emit(
    'update:modelValue',
    Object.entries(v)
      .filter((cur) => !!cur[1])
      .map((cur) => cur[0]),
  ),
);

watch(
  () => props.modelValue,
  (newValue) => {
    newValue.forEach((cur) => (selectedItems[cur] = true));

    Object.keys(selectedItems).forEach((cur) => {
      if (!newValue.includes(cur)) delete selectedItems[cur];
    });
  },
);

const title = computed(() => {
  return props.options
    .filter(({ value }) => !!selectedItems[value])
    .map(({ label }) => label)
    .join(', ');
});

function onToggleContent(isVisible: boolean): void {
  isContentVisible.value = isVisible;
}

function resetSelection() {
  Object.keys(selectedItems).forEach((key) => delete selectedItems[key]);
}

function onToggleSelection(item: MultiSelectItem): void {
  if (!item.isAvailable) return;
  selectedItems[item.value] = !(selectedItems[item.value] ?? false);
}
</script>
<style scoped lang="postcss">
.inactive > label {
  @apply cursor-not-allowed;
}

.open::after {
  content: '';
  @apply absolute top-0 left-[-1pt] bottom-[-1pt] right-[-1pt] border-primary-base;
  @apply border-b-4 !important;
}

.dropdown > * {
  @apply py-2xs;
}

.entry > * {
  @apply cursor-pointer;
}
</style>
