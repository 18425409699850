<template>
  <div v-if="tags.length">
    <div v-if="label" class="text-sm font-bold py-xs md:py-sm">
      {{ t(label) }}
    </div>

    <MultiSelectBox
      v-model="selectedTags"
      :options="tags"
      :has-reset-option="!label"
    />
  </div>
</template>
<script setup lang="ts">
import type { FilterOptions } from '@/components/components/advancedList/useAdvancedList';
import MultiSelectBox from '@/components/multiSelectBox/multi-select-box.vue';

const props = defineProps({
  filterKey: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  filters: {
    type: Object as PropType<Record<string, any>>,
    required: true,
    default: () => ({}) as Record<string, any>,
  },
  filterOptions: {
    type: Object as PropType<FilterOptions>,
    required: true,
    default: () => ({}) as FilterOptions,
  },
});

const emits = defineEmits<{
  (e: 'changeFilter', key: string, value: any): void;
}>();

const { t } = useTrans();

const selectedTags = ref<string[]>(props.filters[props.filterKey] ?? []);

const tags = computed(() => {
  return (
    props.filterOptions[props.filterKey]?.values.map((cur) => ({
      label: cur.label,
      value: cur.id,
      isAvailable: cur.isAvailable,
    })) ?? []
  );
});

watch(selectedTags, (newValue) =>
  emits('changeFilter', props.filterKey, newValue),
);

watch(
  () => props.filters[props.filterKey],
  () => {
    selectedTags.value = props.filters[props.filterKey] ?? [];
  },
);
</script>
